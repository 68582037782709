import React, { useState, useEffect } from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const BackToTop = ({ showBelow }) => {
    const [show, setShow] = useState(showBelow ? false : true)

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!show) setShow(true)
        } else {
            if (show) setShow(false)
        }
    }

    useEffect(() => {
        if (showBelow) {
            window.addEventListener(`scroll`, handleScroll)
            return () => window.removeEventListener(`scroll`, handleScroll)
        }
    })

    const classes = ['c-top-link', ...(show ? ['is-active'] : [])]

    const concatenatedClasses = joinClasses(classes)

    return (
        <button
            className={concatenatedClasses}
            onClick={() => scrollTo('#top')}
        >
            <span
                className="sr-only"
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease"
            >
                Back to top
            </span>
        </button>
    )
}

BackToTop.propTypes = {
    /**
     * Pixel value to show the back to top button after scrolling
     */
    showBelow: PropTypes.number.isRequired
}

BackToTop.defaultProps = {
    showBelow: 250
}

export default BackToTop
